import { useEffect, useState } from "react";
import "../../CSS/yardTable.css";
import { ProfileSettings } from "../Forms/ProfileSettings";
import { Preferences } from "../Forms/Preferences";
import "../../CSS/profileSettings.css";
import { checkAuthentication } from "../../Interceptor/csrftoken";
import { useLanguage } from "../../Media/LanguageContext";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useAuth } from "../../Auth/AuthProvider";

export const Settings = () => {
  const [settingTab, setTab] = useState("Account");
  const [isAuth, setAuth] = useState(false);
  const {user, updateUser} = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    const isAuthenticated = async () => {
      try {
        let check = await checkAuthentication(updateUser);
        if (!check) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          setAuth(true);
        }
      } catch (error) {
        console.log(error)
      }
    };
    isAuthenticated();
  }, []);
  const active = async (e) => {
    const previous = document.getElementsByClassName("selected");
    for (var i = 0; i < previous.length; ++i) {
      previous[i].className = "tab";
    }
    e.target.className = "selected";
    setTab(e.target.id);
  };

  return (
    <div className="global-cont">
      <Helmet>
        <title>Forward | Settings</title>
      </Helmet>
      {isAuth ? (
        <div className="page-overview">
          <div className="cont-wrapper">
            <div className="overview-header">
              <h1 className="global-h1 light-black">
                {t("page-headers.settings")}
              </h1>
            </div>
            <ul className="yard-tabs">
              <li className="selected" onClick={active} id="Account">
                {t("settings.tabs.account")}
              </li>
              <li className="tab" onClick={active} id="Preferences">
                {t("settings.tabs.preferences")}
              </li>
            </ul>
            {settingTab === "Account" ? (
              <ProfileSettings user={user} english={t} />
            ) : null}
            {settingTab === "Preferences" ? (
              <Preferences theme={user.theme} english={t} />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};
