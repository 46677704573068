import "../../CSS/profileSettings.css";
import "../../CSS/checkInTrailer.css";
import "../../CSS/buttons.css";
import { useState } from "react";
import { useLanguage } from "../../Media/LanguageContext";
import instance from "../../Interceptor/api_instance";
import i18next from "i18next";
import { Dots } from "../LoadingAnimations/LoadingAnimations";
import { useAuth } from "../../Auth/AuthProvider";

export const Preferences = ({ theme, english }) => {
  const [radio, setRadio] = useState(theme);
  const {user, updateUser} = useAuth();
  const { change, setChange } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);

  const saveTheme = async (radioTheme) => {
    try {
      await instance.post("api/save-theme/" + radioTheme, user);
      user.theme = radioTheme;
      localStorage.setItem("user", JSON.stringify(user));
      setChange(radioTheme);
      setRadio(radioTheme);
      updateUser(user);
    } catch (e) {
      window.alert(e);
    }
  };

  const handleLanguageChange = async (e) => {
    try {
      setIsLoading(true);
      await instance.post("api/save-language/" + e.target.value);
      user.user_language = e.target.value;
      updateUser(user);
      i18next.changeLanguage(e.target.value);
    } catch {
      window.alert("An error occurred attempting to complete your request.")
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
  }
  return (
    <div className="settings-cont">
      <h2 className="global-h2 black appearance-setting">
        {english("page-headers.appearance")}
      </h2>
      <div className="personal-info-cont">
        <h4 className="global-h4 gray">
          {english("page-subheaders.interface-theme")}
        </h4>
        <div className="themes-cont">
          <label className="theme-option">
            <input
              type="radio"
              name="theme"
              value=""
              className="theme-radio"
              onClick={() => saveTheme("System")}
              checked={radio === "System"}
            />
            <img src={'LoginScreenShot.png'} />
            {radio === "System" && (
              <div className="theme-check-cont">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="49"
                  viewBox="0 0 50 49"
                  fill="none"
                >
                  <g filter="url(#filter0_d_922_8156)">
                    <path
                      d="M25 4.8999C14.22 4.8999 5.39999 13.7199 5.39999 24.4999C5.39999 35.2799 14.22 44.0999 25 44.0999C35.78 44.0999 44.6 35.2799 44.6 24.4999C44.6 13.7199 35.78 4.8999 25 4.8999ZM21.325 34.5449C20.59 34.5449 19.855 34.2999 19.365 33.8099C19.365 33.8099 19.12 33.8099 19.12 33.5649C19.12 33.5649 19.12 33.5649 18.875 33.3199L12.995 27.4399C11.77 26.2149 11.77 24.2549 12.995 23.0299C14.22 21.8049 16.18 21.8049 17.405 23.0299L21.325 26.9499L32.595 15.4349C33.82 14.2099 35.78 14.2099 37.005 15.4349C38.23 16.6599 38.23 18.6199 37.005 19.8449L23.53 33.5649C23.04 34.2999 22.06 34.5449 21.325 34.5449Z"
                      fill="#CFBEFF"
                    />
                    <path
                      d="M37.005 19.8449L23.53 33.5649C23.04 34.2999 22.06 34.5449 21.325 34.5449C20.59 34.5449 19.855 34.2999 19.365 33.8099C19.365 33.8099 19.12 33.8099 19.12 33.5649C19.12 33.5649 19.12 33.5649 18.875 33.3199L12.995 27.4399C11.77 26.2149 11.77 24.2549 12.995 23.0299C14.22 21.8049 16.18 21.8049 17.405 23.0299L21.325 26.9499L32.595 15.4349C33.82 14.2099 35.78 14.2099 37.005 15.4349C38.23 16.6599 38.23 18.6199 37.005 19.8449Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_922_8156"
                      x="-3.8"
                      y="-4.3"
                      width="57.6"
                      height="57.6"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2.15" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.956863 0 0 0 0 0.956863 0 0 0 0 1 0 0 0 0.4 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_922_8156"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_922_8156"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            )}
            <h5 className="global-h5">{english("settings.themes.system")}</h5>
          </label>
          <label className="theme-option">
            <input
              type="radio"
              name="theme"
              className="theme-radio"
              onClick={() => saveTheme("Agramont")}
              checked={radio === "Agramont"}
            />
            <img src={'LoginScreenShot2.png'} alt="" />
            {radio === "Agramont" && (
              <div className="theme-check-cont">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="49"
                  viewBox="0 0 50 49"
                  fill="none"
                >
                  <g filter="url(#filter0_d_922_8156)">
                    <path
                      d="M25 4.8999C14.22 4.8999 5.39999 13.7199 5.39999 24.4999C5.39999 35.2799 14.22 44.0999 25 44.0999C35.78 44.0999 44.6 35.2799 44.6 24.4999C44.6 13.7199 35.78 4.8999 25 4.8999ZM21.325 34.5449C20.59 34.5449 19.855 34.2999 19.365 33.8099C19.365 33.8099 19.12 33.8099 19.12 33.5649C19.12 33.5649 19.12 33.5649 18.875 33.3199L12.995 27.4399C11.77 26.2149 11.77 24.2549 12.995 23.0299C14.22 21.8049 16.18 21.8049 17.405 23.0299L21.325 26.9499L32.595 15.4349C33.82 14.2099 35.78 14.2099 37.005 15.4349C38.23 16.6599 38.23 18.6199 37.005 19.8449L23.53 33.5649C23.04 34.2999 22.06 34.5449 21.325 34.5449Z"
                      fill="#CFBEFF"
                    />
                    <path
                      d="M37.005 19.8449L23.53 33.5649C23.04 34.2999 22.06 34.5449 21.325 34.5449C20.59 34.5449 19.855 34.2999 19.365 33.8099C19.365 33.8099 19.12 33.8099 19.12 33.5649C19.12 33.5649 19.12 33.5649 18.875 33.3199L12.995 27.4399C11.77 26.2149 11.77 24.2549 12.995 23.0299C14.22 21.8049 16.18 21.8049 17.405 23.0299L21.325 26.9499L32.595 15.4349C33.82 14.2099 35.78 14.2099 37.005 15.4349C38.23 16.6599 38.23 18.6199 37.005 19.8449Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_922_8156"
                      x="-3.8"
                      y="-4.3"
                      width="57.6"
                      height="57.6"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2.15" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.956863 0 0 0 0 0.956863 0 0 0 0 1 0 0 0 0.4 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_922_8156"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_922_8156"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            )}
            <h5 className="global-h5">Agramont</h5>
          </label>
        </div>
      </div>
      <div className="personal-info-cont">
        <h4 className="global-h4 gray">{english("page-subheaders.language")}</h4>
        <div className="languages-options">
          {isLoading ? <Dots/> : <button
            className="change-pwd-btn"
            value="en-US"
            onClick={e => handleLanguageChange(e)}
            disabled={user.user_language === "en-US"}
          >
            English
          </button>}
          {isLoading ? <Dots/> : <button
            value="es-MX"
            className="change-pwd-btn"
            onClick={e => handleLanguageChange(e)}
            disabled={user.user_language === "es-MX"}
          >
            Español
          </button>}
        </div>
      </div>
    </div>
  );
};
