import { useEffect, useState } from "react";
import instance from "../../../Interceptor/api_instance";
import Select from "react-select";

const usStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const mexicanStates = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Chiapas",
  "Chihuahua",
  "Coahuila",
  "Colima",
  "Durango",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "México",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán",
  "Zacatecas",
];

export const CheckIn1stForm = ({
  carrying,
  setCarrying,
  trailerType,
  setTrailerType,
  atiBool,
  setBool,
  trailerList,
  setTrailerList,
  carrier,
  setCarrier,
  otherCarrier,
  setOtherCarrier,
  customer,
  setCustomer,
  customerList,
  setCustomerList,
  atiTruckList,
  jfgTruckList,
  truckList,
  onClose,
  english,
  nextForm,
  handleCheckInDetails,
  trailerBody,
  truckBody,
  setTrailerBody,
  setTruckBody,
  inOrOut,
  handleTruckChange,
  handleTrailerChange,
  dimensionOption,
  setDimensionOption,
  customHeight,
  customWidth,
  customLength,
  setCustomWidth,
  setCustomHeight,
  setCustomLength,
  visitor,
  handleVisitorChange,
  visitorList,
  lockTruck,
}) => {
  useEffect(() => {}, [truckBody, trailerBody]);

  const [odoRequired, setOdoRequired] = useState(true);
  const [odometerDefaultValue, setOdometerDefaultValue] = useState("");

  useEffect(() => {
    const determineOdometerDefaultValue = () => {
      if (carrier) {
        if (carrier === "Other") {
          setOdoRequired(false);
          return "";
        } else {
          setOdoRequired(true);
          return;
        }
      }
      if (truckBody.truck) {
        if (
          truckBody.truck.carrier_name !== "JFG" &&
          truckBody.truck.carrier_name !== "ATI"
        ) {
          setOdoRequired(false);
          return "";
        } else {
          setOdoRequired(true);

          return "";
        }
      }
      setOdoRequired(true);
      return "";
    };

    setOdometerDefaultValue(determineOdometerDefaultValue());
  }, [carrier, truckBody]);

  if (!inOrOut) {
    atiBool = true;
  }

  let trailerNumberOptions = [];
  // Prepare options for react-select
  const atiTruckListOptions = atiTruckList?.map((truck) => ({
    value: truck.name,
    label: truck.name, // label is what is displayed in the dropdown
  }));

  const jfgTruckListOptions = jfgTruckList?.map((truck) => ({
    value: truck.name,
    label: truck.name, // label is what is displayed in the dropdown
  }));

  if (inOrOut) {
    trailerNumberOptions = trailerList?.map((trailer) => ({
      value: trailer.id,
      label: `${trailer.unit}-${trailer.unit_number}`,
    }));
  } else {
    trailerNumberOptions = trailerList?.map((trailer) => ({
      value: trailer.trailer.id,
      label: trailer.trailer.trailer_number,
    }));
  }
  const truckCheckoutOptions = truckList?.map((truck) => ({
    value: truck.id,
    label: `${truck.truck.carrier_name}-${truck.truck.truck_number}`,
  }));
  const visitorCheckoutOptions = visitorList?.map((visitor) => ({
    value: visitor.visitor.id,
    label: `${visitor.visitor.first_name} ${visitor.visitor.last_name}`,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px 16px",
      border: "none",
      width: "100%", // Make sure control is full width
      borderRadius: "16px",
      background: "var(--neutral-light-purple, #f4f4ff)",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      color: "#5656b9",
      minHeight: "auto", // Override to handle padding
    }),
    // You might want to adjust the width of the menu to match the control
    container: (provided) => ({
      ...provided,
      width: "100%", // Ensure the dropdown menu is also full width
      color: "#5656b9",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#5656b9",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F4F4FF",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      borderRadius: "24px",
      padding: "16px",
      marginTop: "10px",
    }),
  };

  useEffect(() => {
    if (truckBody === "null") {
    }
  }, [truckBody]);

  // Handling changes from react-select
  const handleTruckNumberChange = (selectedOption) => {
    // Check if selectedOption is not null (it can be null if the selection is cleared)
    const value = selectedOption ? selectedOption.value : "";
    setTruckBody((prevState) => ({ ...prevState, truck_number: value }));
  };

  // Function to handle changes in the react-select dropdown
  const handleTrailerNumberChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    handleCheckInDetails(setTrailerBody, "trailer_number", value);
  };

  // Function for setting trailer type ATI on check in
  const setATI = async (e) => {
    setBool(true);
    setTrailerType(e);

    // Run API request only if user selects Yes on "Is it an ATI trailer" and list is empty
    if (e && trailerList.length === 0) {
      try {
        const trailers = await instance.get("assets/out-trailers/");
        if (trailers.status === 200) {
          setTrailerList(trailers.data);
        }
      } catch (e) {
        window.alert(e);
      }
    }
  };

  const queryCustomers = async (e) => {
    handleCheckInDetails(setTrailerBody, "customer", e.target.value);
    try {
      if (e.target.value !== "") {
        const response = await instance.get(
          "assets/customers/" + e.target.value + "/"
        );
        if (response.status === 429) {
          window.alert("Please refrain from making too many requests.");
          window.location.reload();
        }
        if (response.data !== undefined) {
          setCustomerList(response.data);
        }
      } else {
        handleCheckInDetails(setTrailerBody, "customer", "");
        setCustomerList([]);
      }
    } catch (e) {
      window.alert(e);
    }
  };

  const handleCustomer = (name) => {
    setCustomerList([]);
    handleCheckInDetails(setTrailerBody, "customer", name);
  };

  return (
    <div className="questions-cont">
      <h2 className="global-h2 light-black">
        {inOrOut
          ? english("forms.type.vehicle.check-in.header")
          : english("forms.type.vehicle.check-out.header")}
      </h2>
      <h4 className="global-h4 gray bottom-space">
        {english("forms.type.check-in-header")}
      </h4>
      <form onSubmit={nextForm} id="form-1st">
        {
          <div className="form-group">
            <label className="input-label required">
              {english("forms.type.vehicle.input-headers.carrying-bool")}{" "}
            </label>
            <div className="input-cont">
              <fieldset className="fieldsets">
                <div className="radio-options">
                  <input
                    type="radio"
                    id="yes1"
                    name="group1"
                    className="radio-input"
                    checked={carrying}
                    onChange={(e) => setCarrying(true)}
                    required
                  />
                  <label>{english("general.yes")}</label>
                </div>
                <div className="radio-options">
                  <input
                    type="radio"
                    name="group1"
                    className="radio-input"
                    checked={carrying === false}
                    onChange={(e) => setCarrying(false)}
                  />
                  <label>No</label>
                </div>
              </fieldset>
            </div>
          </div>
        }
        {carrying && inOrOut ? (
          <div className="form-group">
            <label className="input-label required">
              {english("forms.type.vehicle.input-headers.ati-bool")}{" "}
            </label>
            <div className="input-cont">
              <fieldset className="fieldsets">
                <div className="radio-options">
                  <input
                    type="radio"
                    name="group2"
                    className="radio-input"
                    checked={trailerType}
                    onChange={(e) => setATI(true)}
                    required
                  />
                  <label>{english("general.yes")}</label>
                </div>
                <div className="radio-options">
                  <input
                    type="radio"
                    id="no1"
                    name="group2"
                    className="radio-input"
                    checked={trailerType === false}
                    onChange={(e) => setATI(false)}
                  />
                  <label>No</label>
                </div>
              </fieldset>
            </div>
          </div>
        ) : null}
        <div className="form-check-in">
          {inOrOut ? (
            <div className="form-group">
              <label className="input-label required">
                {english("yard-tables.table-columns.carrier")}
              </label>
              <div className="input-cont">
                <select
                  value={carrier}
                  onChange={(e) => setCarrier(e.target.value)}
                  id="carrier"
                  required
                >
                  <option value="">
                    {english("forms.type.vehicle.placeholders.select-carrier")}
                  </option>
                  <option value="JFG">JFG</option>
                  <option value="ATI">ATI</option>
                  <option value="Other">
                    {english("forms.type.vehicle.placeholders.other")}
                  </option>
                </select>
                {/* Use conditional rendering to show/hide the input based on selected value */}
                {carrier === "Other" && (
                  <input
                    id="other-carrier"
                    type="text"
                    value={otherCarrier}
                    className="text-input"
                    placeholder={english(
                      "forms.type.vehicle.placeholders.other-carrier"
                    )}
                    onChange={(e) => setOtherCarrier(e.target.value)}
                    required
                  />
                )}
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <label className="input-label required">
              {english("forms.type.vehicle.input-headers.truck-number")}{" "}
            </label>
            {carrier === "ATI" && inOrOut ? (
              <div id="checkin-truck-number" className="input-cont">
                <Select
                  value={atiTruckListOptions.find(
                    (option) => option.value === truckBody.truck_number
                  )}
                  onChange={handleTruckNumberChange}
                  options={atiTruckListOptions}
                  placeholder={english(
                    "forms.type.vehicle.placeholders.select-num"
                  )}
                  isClearable={true} // Allows users to clear the selected value
                  isSearchable={true} // Allows users to search through the dropdown
                  styles={customStyles}
                  unstyled={true}
                  required
                  isLoading={atiTruckListOptions.length === 0}
                  loadingMessage={() => "Loading options, please wait..."}
                />
              </div>
            ) : null}
            {carrier === "JFG" && inOrOut ? (
              <div id="checkin-truck-number" className="input-cont">
                <Select
                  value={jfgTruckListOptions.find(
                    (option) => option.value === truckBody.truck_number
                  )}
                  onChange={handleTruckNumberChange}
                  options={jfgTruckListOptions}
                  placeholder={english(
                    "forms.type.vehicle.placeholders.select-num"
                  )}
                  isClearable={true} // Allows users to clear the selected value
                  isSearchable={true} // Allows users to search through the dropdown
                  styles={customStyles}
                  unstyled={true}
                  required
                  isLoading={jfgTruckListOptions.length === 0}
                  loadingMessage={() => "Loading options, please wait..."}
                />
              </div>
            ) : null}
            {carrier === "Other" && inOrOut ? (
              <div className="input-cont">
                <input
                  className="text-input"
                  id="truck-num"
                  type="text"
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTruckBody,
                      "truck_number",
                      e.target.value
                    )
                  }
                  value={truckBody.truck_number}
                  required
                />
              </div>
            ) : null}

            {/* This select tag is for check out only. List of trucks in yard */}
            {!inOrOut ? (
              <>
                <div
                  id="checkout-truck-number"
                  className="input-cont input-cont-message"
                >
                  <Select
                    value={truckCheckoutOptions.find(
                      (option) => option.value === truckBody.id
                    )}
                    onChange={handleTruckChange}
                    options={truckCheckoutOptions}
                    isClearable={true} // Allows users to clear the selected value
                    isSearchable={true} // Allows users to search through the dropdown
                    styles={customStyles}
                    unstyled={true}
                    required
                    isLoading={truckCheckoutOptions.length === 0}
                    loadingMessage={() => "Loading options, please wait..."}
                    isDisabled={lockTruck}
                  />
                  {lockTruck && trailerBody.reservation.truck_asset && (
                    <p className="error">
                      {!truckBody.truck
                        ? english("forms.type.vehicle.check-out.truck-not") +
                          trailerBody.trailer_number +
                          " " +
                          english("forms.type.vehicle.check-out.by") +
                          trailerBody.reservation.user.first_name +
                          " " +
                          trailerBody.reservation.user.last_name +
                          " " +
                          english("forms.type.vehicle.check-out.truck-not2") +
                          " " +
                          trailerBody.reservation.truck_asset.company +
                          "-" +
                          trailerBody.reservation.truck_asset.name
                        : truckBody.truck.carrier_name +
                          "-" +
                          truckBody.truck.truck_number +
                          " " +
                          english("forms.type.vehicle.check-out.reserved") +
                          " " +
                          trailerBody.trailer_number +
                          " " +
                          english("forms.type.vehicle.check-out.by") +
                          trailerBody.reservation.user?.first_name +
                          " " +
                          trailerBody.reservation.user?.last_name}
                    </p>
                  )}

                  {trailerBody.reservation &&
                    !trailerBody.reservation.truck_asset && (
                      <p className="error">
                        Trailer {trailerBody.trailer_number} is reserved by{" "}
                        {trailerBody.reservation.user?.first_name}{" "}
                        {trailerBody.reservation.user?.last_name}
                      </p>
                    )}
                </div>
              </>
            ) : null}
          </div>

          {/* This select tag is for check out only. List of trailers in yard */}
          {carrying && !inOrOut ? (
            <div className="form-group">
              <label className="input-label required">
                {english("forms.type.vehicle.placeholders.select-trailer")}{" "}
              </label>
              <div id="checkout-truck-number" className="input-cont">
                <Select
                  required
                  value={trailerNumberOptions.find(
                    (option) => option.value === trailerBody.id
                  )}
                  onChange={handleTrailerChange}
                  options={trailerNumberOptions}
                  placeholder={
                    trailerBody.id
                      ? trailerBody.trailer_number
                      : english(
                          "forms.type.vehicle.placeholders.select-trailer"
                        )
                  }
                  isClearable={true} // Allows users to clear the selected value
                  isSearchable={true} // Allows users to search through the dropdown
                  styles={customStyles}
                  unstyled={true}
                  isLoading={trailerNumberOptions.length === 0}
                  loadingMessage={() => "Loading options, please wait..."}
                />
              </div>
            </div>
          ) : null}

          {carrier === "Other" ? (
            <div className="form-group">
              <label className="input-label required">
                {english("forms.type.vehicle.input-headers.truck-plate")}
              </label>
              <div className="input-cont">
                <input
                  type="text"
                  id="truckPlates"
                  value={truckBody.truckPlates}
                  className="text-input"
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTruckBody,
                      "truckPlates",
                      e.target.value
                    )
                  }
                  placeholder={english(
                    "forms.type.vehicle.placeholders.license-plate"
                  )}
                />
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <label className={`input-label ${odoRequired && "required"}`}>
              {english("forms.type.vehicle.input-headers.odometer")}
            </label>
            <div className="input-cont">
              <input
                type="number"
                id="odometer"
                min={0}
                className="text-input"
                placeholder={english(
                  "forms.type.vehicle.placeholders.enter-miles"
                )}
                value={truckBody.odometer}
                onChange={(e) =>
                  handleCheckInDetails(setTruckBody, "odometer", e.target.value)
                }
                required={odoRequired}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="input-label required">
              {english("forms.type.vehicle.input-headers.truck_condition")}
            </label>
            <div className="input-cont">
              <div>
                <select
                  id="conditionSelect"
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTruckBody,
                      "truck_condition",
                      e.target.value
                    )
                  }
                  value={truckBody === "null" ? "" : truckBody.truck_condition}
                  required
                >
                  <option value="" selected disabled>
                    {english(
                      "forms.type.vehicle.placeholders.select-condition.header"
                    )}
                  </option>
                  <option value="Good">
                    {english(
                      "forms.type.vehicle.placeholders.select-condition.good"
                    )}
                  </option>
                  <option value="Okay">Okay</option>
                  <option value="Poor">
                    {english(
                      "forms.type.vehicle.placeholders.select-condition.poor"
                    )}
                  </option>
                  <option value="Critical">
                    {english(
                      "forms.type.vehicle.placeholders.select-condition.critical"
                    )}
                  </option>
                </select>
                <textarea
                  type="text"
                  className="text-input"
                  placeholder={english("forms.type.vehicle.placeholders.notes")}
                  value={
                    truckBody.truck_condition_notes
                      ? truckBody.truck_condition_notes
                      : ""
                  }
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTruckBody,
                      "truck_condition_notes",
                      e.target.value
                    )
                  }
                  required={
                    truckBody.truck_condition === "Poor" ||
                    truckBody.truck_condition === "Critical"
                      ? true
                      : false
                  }
                  disabled={!inOrOut}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="input-label required">
              {english("yard-tables.search-filters.dropdowns.driver")}{" "}
            </label>
            {inOrOut ? (
              <div className="input-cont">
                <input
                  type="text"
                  id="driver-first"
                  className="text-input"
                  placeholder={english(
                    "forms.type.newDriver.input-headers.firstName"
                  )}
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTruckBody,
                      "first_name",
                      e.target.value
                    )
                  }
                  value={truckBody.first_name}
                  required
                />
                <input
                  type="text"
                  id="driver-last"
                  className="text-input"
                  placeholder={english(
                    "forms.type.newDriver.input-headers.lastName"
                  )}
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTruckBody,
                      "last_name",
                      e.target.value
                    )
                  }
                  value={truckBody.last_name}
                  required
                />
              </div>
            ) : (
              <div className="input-cont">
                <Select
                  value={visitorCheckoutOptions.find(
                    (option) => option.value === visitor.id
                  )}
                  onChange={handleVisitorChange}
                  options={visitorCheckoutOptions}
                  placeholder={
                    visitor.id
                      ? `${visitor.first_name} ${visitor.last_name}`
                      : english("forms.type.vehicle.placeholders.select-driver")
                  }
                  isClearable={true} // Allows users to clear the selected value
                  isSearchable={true} // Allows users to search through the dropdown
                  styles={customStyles}
                  unstyled={true}
                  required
                  isLoading={visitorCheckoutOptions.length === 0}
                  loadingMessage={() => "Loading options, please wait..."}
                />
              </div>
            )}
          </div>
        </div>
        {carrying && atiBool ? (
          <div className="form-check-in">
            {inOrOut ? (
              <div className="form-group">
                <label className="input-label required">
                  {english("forms.type.vehicle.input-headers.trailer-number")}{" "}
                </label>
                <div className="input-cont">
                  {trailerType ? (
                    <Select
                      required
                      value={trailerNumberOptions.find(
                        (option) => option.value === trailerBody.trailer_number
                      )}
                      onChange={handleTrailerNumberChange}
                      options={trailerNumberOptions}
                      placeholder={english(
                        "forms.type.vehicle.placeholders.select-num"
                      )}
                      isClearable={true} // Allows users to clear the selected value
                      isSearchable={true} // Allows users to search through the dropdown
                      styles={customStyles}
                      unstyled={true}
                      isLoading={trailerNumberOptions.length === 0}
                      loadingMessage={() => "Loading options, please wait..."}
                    />
                  ) : (
                    <input
                      id="trailer-num"
                      type="text"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.num"
                      )}
                      onChange={(e) =>
                        handleCheckInDetails(
                          setTrailerBody,
                          "trailer_number",
                          e.target.value
                        )
                      }
                      value={trailerBody.trailer_number}
                      className="text-input"
                      required
                    />
                  )}
                </div>
              </div>
            ) : null}
            {carrying ? (
              <div className="form-group">
                <label className="input-label required">
                  {english("forms.type.vehicle.input-headers.trailer-dim")}
                </label>
                <div className="input-cont">
                  <fieldset className="fieldsets">
                    <div className="radio-options">
                      <input
                        id="dimension1"
                        type="radio"
                        name="dimension"
                        className="radio-input"
                        checked={dimensionOption === 1}
                        onClick={() => setDimensionOption(1)}
                        required
                      />
                      <label>53'</label>
                    </div>
                    <div className="radio-options">
                      <input
                        type="radio"
                        name="dimension"
                        className="radio-input"
                        checked={dimensionOption === 2}
                        onClick={() => setDimensionOption(2)}
                      />
                      <label>48'</label>
                    </div>
                    <div className="radio-options">
                      <input
                        type="radio"
                        name="dimension"
                        className="radio-input"
                        checked={dimensionOption === 3}
                        onClick={() => setDimensionOption(3)}
                      />
                      <label>
                        {english("forms.type.vehicle.placeholders.other")}
                      </label>
                    </div>
                  </fieldset>
                </div>
              </div>
            ) : null}
            {carrying && dimensionOption === 3 ? (
              <div className="form-group">
                <label className="input-label required">
                  {english("forms.type.vehicle.input-headers.other-dimensions")}
                </label>
                <div className="input-cont">
                  <input
                    type="number"
                    step="0.01"
                    min={0}
                    className="text-input"
                    value={customLength}
                    onChange={(e) => setCustomLength(e.target.value)}
                    placeholder={english(
                      "forms.type.vehicle.placeholders.length"
                    )}
                  />
                  <input
                    type="number"
                    step="0.01"
                    min={0}
                    className="text-input"
                    value={customWidth}
                    onChange={(e) => setCustomWidth(e.target.value)}
                    placeholder={english(
                      "forms.type.vehicle.placeholders.width"
                    )}
                  />
                  <input
                    type="number"
                    step="0.01"
                    min={0}
                    className="text-input"
                    value={customHeight}
                    onChange={(e) => setCustomHeight(e.target.value)}
                    placeholder={english(
                      "forms.type.vehicle.placeholders.height"
                    )}
                  />
                </div>
              </div>
            ) : null}
            {!trailerType ? (
              <div className="form-group">
                <label className="input-label required">
                  {english("forms.type.vehicle.input-headers.trailer-plate")}{" "}
                </label>
                <div className="input-cont">
                  <input
                    id="trailerPlate"
                    type="text"
                    placeholder={english(
                      "forms.type.vehicle.placeholders.license-plate"
                    )}
                    className="text-input"
                    onChange={(e) =>
                      handleCheckInDetails(
                        setTrailerBody,
                        "license_plate",
                        e.target.value
                      )
                    }
                    value={
                      trailerBody === "null" ? "" : trailerBody.license_plate
                    }
                    required
                  />
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <label className="input-label required required">
                {english("yard-tables.search-filters.dropdowns.contents")}{" "}
              </label>
              <div className="input-cont">
                <select
                  id="contentSelect"
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTrailerBody,
                      "is_loaded",
                      e.target.value
                    )
                  }
                  value={trailerBody === "null" ? "" : trailerBody.is_loaded}
                  required
                >
                  <option value="" disabled selected>
                    {english("forms.type.vehicle.placeholders.select-content")}
                  </option>
                  <option value={true}>
                    {english("forms.type.vehicle.placeholders.loaded")}
                  </option>
                  <option value={false}>
                    {english("forms.type.vehicle.placeholders.empty")}
                  </option>
                </select>
              </div>
            </div>
            {trailerBody.is_loaded === "true" ||
            !inOrOut ||
            trailerBody.is_loaded === true ? (
              <div className="form-group">
                <label className="input-label required">
                  {english("yard-tables.search-filters.dropdowns.customer")}
                </label>
                <div className="input-cont">
                  <div className="search-input">
                    <input
                      id="searchCustomer"
                      type="text"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.search-customer"
                      )}
                      value={customer}
                      onChange={(e) => setCustomer(e.target.value)}
                      required
                    />
                    {customerList.length > 0 ? (
                      <ul className="search-results">
                        {customerList.map((customer, index) => (
                          <li
                            key={index}
                            onClick={() => handleCustomer(customer.name)}
                          >
                            {customer.name}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <label className="input-label required required">
                {english("forms.type.vehicle.placeholders.seal")}{" "}
              </label>
              <div className="input-cont">
                <select
                  id="sealSelect"
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTrailerBody,
                      "is_seal",
                      e.target.value
                    )
                  }
                  value={trailerBody === "null" ? "" : trailerBody.is_seal}
                  required
                >
                  <option value="" disabled selected>
                    {english("forms.type.vehicle.placeholders.select-status")}
                  </option>
                  <option value={true}>
                    {english("forms.type.vehicle.placeholders.seal")}
                  </option>
                  <option value={false}>
                    {english("forms.type.vehicle.placeholders.noseal")}
                  </option>
                </select>
              </div>
            </div>
            {trailerBody.is_seal === "true" || trailerBody.is_seal === true ? (
              <div className="form-group">
                <label className="input-label required">
                  {english("forms.type.vehicle.input-headers.sealNum")}{" "}
                </label>
                <div className="input-cont">
                  <input
                    id="sealNum"
                    onChange={(e) =>
                      handleCheckInDetails(
                        setTrailerBody,
                        "seal_number",
                        e.target.value
                      )
                    }
                    value={
                      trailerBody === "null" ? "" : trailerBody.seal_number
                    }
                    className="text-input"
                    placeholder={english("forms.type.vehicle.placeholders.num")}
                    required
                  />
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <label className="input-label required">
                {english("forms.type.vehicle.input-headers.trailer_condition")}
              </label>
              <div className="input-cont">
                <div>
                  <select
                    id="conditionSelect"
                    onChange={(e) =>
                      handleCheckInDetails(
                        setTrailerBody,
                        "trailer_condition",
                        e.target.value
                      )
                    }
                    value={
                      trailerBody === "null"
                        ? ""
                        : trailerBody.trailer_condition
                    }
                    required
                  >
                    <option value="" selected disabled>
                      {english(
                        "forms.type.vehicle.placeholders.select-condition.header"
                      )}
                    </option>
                    <option value="Good">
                      {english(
                        "forms.type.vehicle.placeholders.select-condition.good"
                      )}
                    </option>
                    <option value="Okay">Okay</option>
                    <option value="Poor">
                      {english(
                        "forms.type.vehicle.placeholders.select-condition.poor"
                      )}
                    </option>
                    <option value="Critical">
                      {english(
                        "forms.type.vehicle.placeholders.select-condition.critical"
                      )}
                    </option>
                  </select>
                  <textarea
                    type="text"
                    className="text-input"
                    placeholder={english(
                      "forms.type.vehicle.placeholders.notes"
                    )}
                    value={
                      trailerBody === "null"
                        ? ""
                        : trailerBody.trailer_condition_notes
                    }
                    onChange={(e) =>
                      handleCheckInDetails(
                        setTrailerBody,
                        "trailer_condition_notes",
                        e.target.value
                      )
                    }
                    required={
                      trailerBody.trailer_condition === "Poor" ||
                      trailerBody.trailer_condition === "Critical"
                        ? true
                        : false
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {!inOrOut ? (
          <>
            <div className="form-group">
              <label className="input-label required">
                {english("yard-tables.search-filters.dropdowns.destination")}
              </label>
              <div className="input-cont">
                <select
                  id="country"
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTruckBody,
                      "country",
                      e.target.value
                    )
                  }
                  value={truckBody.country}
                  required
                >
                  <option value="" selected disabled>
                    {english(
                      "forms.type.vehicle.placeholders.destination.country"
                    )}
                  </option>
                  <option value="USA">
                    {english("forms.type.vehicle.placeholders.usa")}
                  </option>
                  <option value="Mexico">Mexico</option>
                </select>
                <select
                  id="state"
                  value={truckBody.countryState}
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTruckBody,
                      "countryState",
                      e.target.value
                    )
                  }
                  required
                >
                  <option value="" selected disabled>
                    {english(
                      "forms.type.vehicle.placeholders.destination.state"
                    )}
                  </option>
                  {truckBody.country === "USA"
                    ? usStates.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))
                    : mexicanStates.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="input-label"></label>
              <div className="input-cont">
                <input
                  required
                  type="text"
                  id="city"
                  className="text-input"
                  placeholder={english(
                    "forms.type.vehicle.placeholders.destination.City"
                  )}
                  value={truckBody.city}
                  onChange={(e) =>
                    handleCheckInDetails(setTruckBody, "city", e.target.value)
                  }
                />
              </div>
            </div>
          </>
        ) : null}
        {inOrOut ? (
          <>
            <div className="form-group">
              <label className="input-label required">
                {english("forms.type.vehicle.input-headers.origin")}
              </label>
              <div className="input-cont">
                <select
                  id="country"
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTruckBody,
                      "country",
                      e.target.value
                    )
                  }
                  value={truckBody.country}
                  required
                >
                  <option value="" selected disabled>
                    {english(
                      "forms.type.vehicle.placeholders.destination.country"
                    )}
                  </option>
                  <option value="USA">
                    {english("forms.type.vehicle.placeholders.usa")}
                  </option>
                  <option value="Mexico">Mexico</option>
                </select>
                <select
                  id="state"
                  value={truckBody.countryState}
                  onChange={(e) =>
                    handleCheckInDetails(
                      setTruckBody,
                      "countryState",
                      e.target.value
                    )
                  }
                  required
                >
                  <option value="">
                    {english(
                      "forms.type.vehicle.placeholders.destination.state"
                    )}
                  </option>
                  {truckBody.country === "USA"
                    ? usStates.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))
                    : mexicanStates.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="input-label"></label>
              <div className="input-cont">
                <input
                  id="city"
                  type="text"
                  className="text-input"
                  placeholder={english(
                    "forms.type.vehicle.placeholders.destination.City"
                  )}
                  value={truckBody.city}
                  onChange={(e) =>
                    handleCheckInDetails(setTruckBody, "city", e.target.value)
                  }
                />
              </div>
            </div>
          </>
        ) : null}
        <div className="form-group">
          <label className="input-label">
            {english("forms.type.vehicle.input-headers.notes")}
          </label>
          <div className="input-cont">
            <input
              className="text-input"
              placeholder={english("forms.type.vehicle.placeholders.notes")}
              type="text"
              value={truckBody.generalNotes}
              onChange={(e) =>
                handleCheckInDetails(
                  setTruckBody,
                  "generalNotes",
                  e.target.value
                )
              }
            />
          </div>
        </div>
        <div className="full-cont">
          <button className="change-pwd-btn" id="continue-btn" type="submit">
            {english("buttons.continue")}
          </button>
          <button className="cancel-btn" onClick={onClose}>
            {english("buttons.cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};
