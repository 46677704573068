import React from "react";
import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "../../CSS/navbar.css";
import { Logo } from "../../Media/logos";
import { useTranslation } from "react-i18next";
import {
  OpenSidebarIcon,
  OverviewIcon,
  InventoryIcon,
  LogoutIcon,
  SettingsIcon,
  CheckInIcon,
  CheckOutIcon,
} from "../../Media/Icons";

import BlankSelect from "../Inputs/BlankSelect";
import { useAuth } from "../../Auth/AuthProvider";

export const SideBar2 = () => {
  const [isAuth, setIsAuth] = useState(false);
  const location = useLocation();
  const [active, setActive] = useState(false);
  const { user } = useAuth();
  const [isSideOpen, setIsSideOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    try {
      if (user !== null) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    } catch (e) {
      window.alert("Error occured loading: " + e);
    }
  }, [user]);

  // content shift on sidebar open
  const contentShift = () => {
    let content = document.querySelector(".page-overview");
    let topNav = document.querySelector(".navbarCont")
    content.classList.toggle("shift");
    topNav.classList.toggle("shift");
  };

  const openSideBar = () => {
    setIsSideOpen(!isSideOpen);
    let sidebar = document.getElementById("sidebar");
    setActive(!active);

    if (window.innerWidth < 1300) {
      contentShift();
    }

    !active
      ? (sidebar.className = "sidebar-active ")
      : (sidebar.className = "sidebar ");
    sidebar.className =
      sidebar.className +
      (user.theme === "Agramont" ? "sidebar-agramont" : "sidebar-system");
  };

  const closeSideBarOnRoute = () => {
    setIsSideOpen(false);
    if (window.innerWidth < 1300 && active) {
      contentShift();
    }

    let sidebar = document.getElementById("sidebar");
    setActive(false);
    sidebar.className =
      "sidebar " +
      (user.theme === "Agramont" ? "sidebar-agramont" : "sidebar-system");
  };

  const isRouteActive = (route) => location.pathname === route;

  const handleOnSelect = () => {
    closeSideBarOnRoute();
    toggleOpen();
  };

  const [isOpen, setIsOpen] = useState(Array(3).fill(false));

  const navRoutes = {
    Guard: {
      role: "Guard",
      url: [
        "/guard-overview",
        "/inventory-view",
        "/check-in-trailer",
        "/check-out",
        ...(user ? user.groups.some(group => group.name === "Guard Supervisor") ? ["/backlog"] : [] : []),
      ],
      text: [
        t("page-headers.overview"),
        t("page-headers.inventory"),
        t("page-headers.check-in"),
        t("page-headers.check-out"),
        ...(user ? user.groups.some(group => group.name === "Guard Supervisor") ? [t("page-headers.backlog")] : [] : [])
      ],
      icon: [
        <OverviewIcon />,
        <InventoryIcon />,
        <CheckInIcon />,
        <CheckOutIcon />,
        ...(user ? user.groups.some(group => group.name === "Guard Supervisor") ? [<OverviewIcon/>] : [] : [])
      ],
    },
    Dispatch: {
      role: "Dispatch",
      url: [
        "/dispatch-overview",
        "/inventory-view",
        "/reservations",
        "/backlog",
      ],
      text: [
        t("page-headers.overview"),
        t("page-headers.inventory"),
        t("page-headers.reservations"),
        t("page-headers.backlog"),
      ],
      icon: [
        <OverviewIcon />,
        <InventoryIcon />,
        <CheckInIcon />,
        <OverviewIcon />,
      ],
    },
    Safety: {
      role: "Safety",
      url: ["/guard-overview", "/new-driver", "/driving-team", "/check-in-trailer", "/check-out", "/backlog"],
      text: [
        t("page-headers.overview"),
        t("page-headers.newDriver"),
        t("page-headers.driving-team"),
        t("page-headers.check-in"),
        t("page-headers.check-out"),
        t("page-headers.backlog"),
      ],
      icon: [<InventoryIcon />, <OverviewIcon />, <InventoryIcon />, <CheckInIcon />,
      <CheckOutIcon />, <InventoryIcon />],
    },
  };

  const toggleOpen = (index) => {
    const updatedOpenStates = isOpen.map((state, i) =>
      i === index ? !state : false
    );
    setIsOpen(updatedOpenStates);
  };
  return (
    <>
      {isAuth && user ? (
        <div
          id="sidebar"
          className={
            user.theme === "Agramont"
              ? "sidebar sidebar-agramont"
              : "sidebar sidebar-system"
          }
        >
          <div className="sidebar-header">
            <a className="logo-a" id="logo-a" href="/">
              <Logo fillColor={user.theme === "System" ? "#FFFF" : "#D43732"} />
              <h4 className="sidebar-name">Forward</h4>
            </a>
            <div onClick={openSideBar}>
              <OpenSidebarIcon
                color={user.theme === "System" ? "#CFBEFF" : "#2EBCEE"}
              />
            </div>
          </div>
          <div className="side-menu">
            <ul className="menu">
              {user.role === "Admin"
                ? Object.values(navRoutes).map((item, index) => (
                  <li
                    key={index}
                    onClick={isSideOpen ? null : openSideBar}
                    className="menu-item text-dark"
                  >
                    <BlankSelect
                      onSelect={handleOnSelect}
                      link={navRoutes[item.role].url}
                      key={index}
                      text={item.role}
                      options={item.text}
                      isOpen={isOpen[index]}
                      onToggleOpen={() => toggleOpen(index)}
                    />
                  </li>
                ))
                : navRoutes[user.role]?.text.map((item, index) => (
                  <li
                    className={`menu-item ${isRouteActive(navRoutes[user.role].url[index])
                        ? "active"
                        : "inactive"
                      }`}
                    key={index}
                  >
                    <div onClick={closeSideBarOnRoute}>
                      <Link to={navRoutes[user.role].url[index]}>
                        {React.cloneElement(
                          navRoutes[user.role].icon[index],
                          {
                            color: isRouteActive(
                              navRoutes[user.role].url[index]
                            )
                              ? "white"
                              : user.theme === "System"
                                ? "#CFBEFF"
                                : "#2EBCEE",
                          }
                        )}
                        <span
                          className={
                            isRouteActive(navRoutes[user.role].url[index])
                              ? "active-span"
                              : user.theme === "System"
                                ? "s-inactive"
                                : "a-inactive"
                          }
                        >
                          {item}
                        </span>
                      </Link>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <div className="side-menu logout">
            <ul className="menu">
              <li
                className={`menu-item ${isRouteActive("/settings") ? "active" : "inactive"
                  }`}
              >
                <div onClick={closeSideBarOnRoute}>
                  <Link to="/settings">
                    <SettingsIcon
                      color={
                        isRouteActive("/settings")
                          ? "white"
                          : user.theme === "System"
                            ? "#CFBEFF"
                            : "#2EBCEE"
                      }
                    />
                    <span
                      className={
                        isRouteActive("/settings")
                          ? "active-span"
                          : user.theme === "System"
                            ? "s-inactive"
                            : "a-inactive"
                      }
                    >
                      {t("page-headers.settings")}
                    </span>
                  </Link>
                </div>
              </li>
              <li
                className={`menu-item ${isRouteActive("/logout") ? "active" : "inactive"
                  }`}
              >
                <Link to="/logout">
                  <LogoutIcon
                    color={
                      isRouteActive("/logout")
                        ? "white"
                        : user.theme === "System"
                          ? "#CFBEFF"
                          : "#2EBCEE"
                    }
                  />
                  <span
                    className={
                      isRouteActive("/logout")
                        ? "active-span"
                        : user.theme === "System"
                          ? "s-inactive"
                          : "a-inactive"
                    }
                  >
                    {t("page-headers.logout")}
                  </span>
                </Link>
              </li>
              <p className="build-number">
                {process.env.REACT_APP_BUILD_NUMBER}
              </p>
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};