import '../../CSS/navbar.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../Media/LanguageContext';
import { ProfilePhoto } from '../../Media/PlaceHolders';
import { useAuth } from '../../Auth/AuthProvider';

export const Navigation = () => {
  const [isAuth, setIsAuth] = useState(false);
  const {user} = useAuth();
  const { pfp } = useLanguage();

  useEffect(() => {
    try {
      if (user !== null) {
        setIsAuth(true);
      }
      else {
        setIsAuth(false);
      }
    } catch (e) {
      window.alert("Error occurred loading: " + e)
    }
  }, [user]);

  return (
    <div>
      {isAuth && user ?
        <div className='navbarCont'>
          {user.groups.some(group => ["Safety", "Guard"].includes(group.name)) ? <h2 className='global-h2 light-primary-lilac'>{user.location.loc_name}</h2> : <div></div>}
          <div className='profile-content'>
            <Link to="/settings">
              {pfp !== 'null' ? <img src={pfp} className='profile_pic'/> : <ProfilePhoto />}
              <div className='profile-id'>
                <h4 className='global-h4 light-black'>{user.first_name} {user.last_name}</h4>
                <h5 className='global-h5 gray'>{user.email}</h5>
              </div>
            </Link>
          </div>
        </div>
        : null}
    </div>
  );
}