import "../../CSS/loadingAnimations.css";

export const Dots = () => {
  return (
    <div className="loading-cont">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const Spinner = () => {
  return (
    <div className="loading-cont">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const SmallSpinner = () => {
  return (
    <div className="lds-roller small-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export const TablePlaceHolder = (filters) => {
  return (
    <>
      <div
        style={{ width: "70vw", overflow: "hidden" }}
        className="loading-filters-cont"
      >
        {[...Array(filters["filter"])].map((_, index) =>
          filters["filter"] ? (
            <div key={index} className="loading-filter"></div>
          ) : (
            <></>
          )
        )}
        {filters["filter"] ? (
          <div
            style={{ width: "210px", height: "30px" }}
            className="loading-filter"
          ></div>
        ) : (
          <></>
        )}
      </div>
      <div style={{ width: "70vw", overflow: "hidden" }} className="table-cont">
        <div className="header-loading"></div>
        {[...Array(10)].map((_, index) => (
          <div className="row-loading" key={index}></div>
        ))}
      </div>
    </>
  );
};
