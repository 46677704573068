import React, { createContext, useContext, useState } from 'react';
import i18next from 'i18next';


const AuthContext = createContext();
export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('pfp_url', userData.profile_pic);
    localStorage.setItem('user', JSON.stringify(userData));
    i18next.changeLanguage(userData.user_language);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  const updateUser = (user) => {
    setUser(user);
  }

  return (
    <AuthContext.Provider value={{ user, updateUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
